import React, { useEffect, useState } from 'react';
import { handleGetCookies } from '../../../utils/handleCookies';
import { handleGetVideo } from '../../../utils/handleVideo';
import './player.css'

const Player = (props) => {
  const { panelsOptions, content, appearance} = props;

  useEffect(() => {
          var videoContainer = document.querySelector(".web-player");
          var videoSettings = {
            imgLiveEnded: "https://img.new.livestream.com/events/00000000007094ca/64b64680-d817-4903-92d4-ec67ea57cc28_480640.jpg",
            contentURL: content[0].url,
            aspectRatio: [16,9],
            deviceId: handleGetCookies('deviceId'),
            appName: handleGetCookies('appName'),
            autoPlay: true
          };
         const ybvrplayer = new window.YBVRPlayer(videoContainer, videoSettings);

  },[]);
  useEffect( () =>{
    if(appearance !== undefined){
      const spinner = document.getElementsByClassName("ybvr-waiting-spinner");
      spinner[0].style.borderLeftColor = appearance.primaryColor;
    }
  });
  useEffect( () => () => console.log("unmount"), [] );

  return (
            <div className="player-container">
               <div className="container video-container mx-auto px-20">
                 <div className="web-player w-full"></div>
                 <script src="./ybvr-web-player.js" async={true}></script>
               </div>
             </div>
  )
};

export default Player;
