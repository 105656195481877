import React from 'react';
import './carouselItem.css'
const CarouselItem = (props) => {
    const {panel} = props;
    return (
        <div >
            {/* <img className="h-80" src="https://images.unsplash.com/photo-1600054800747-be294a6a0d26?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1053&q=80" /> */}
            <img className=" h-120 carousel-item" src={panel.videos[0].descriptionPoster} />
        </div>
        )
};


export default CarouselItem;
