import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppComponent from './components/App.jsx';
import reportWebVitals from './reportWebVitals';
import { getContentList } from './utils/handleConfigApi';
import { trackPromise } from 'react-promise-tracker';
import { handleCreateCookie, handleGetCookies } from './utils/handleCookies';
import { Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'


class App extends React.Component{
  
  constructor(props) {
    super(props);
    this.state = {
      contentList:{},
    };
  }
    componentDidMount() {
        const id = handleGetCookies('deviceId') ?  handleGetCookies('deviceId')  : handleCreateCookie()
        trackPromise(getContentList(id).then(res=>this.setState({contentList:res})))     
    }
   
    render(){
        return   <AlertProvider template={AlertTemplate} >
                  <AppComponent contentList={this.state.contentList}></AppComponent>
                </AlertProvider>
    }
  }

ReactDOM.render(
    <App/>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
