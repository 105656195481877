import React from 'react';
import Title from '../../atoms/title/title';
import './titleBar.css'

const TitleBar = (props) => {
    const {content, color} = props;
    const textStyle = {
        color: color
    }
    return (
        <div className="h-20 w-full flex flex-row">
            <div className="h-full flex w-1/4 flex-wrap content-center items-center space-x-4 pl-10">
                <div className="return-home flex content-center items-center space-x-4 md:flex-shrink sm:flex-shrink">
                    <a href="/" className="return-arrow" id="test">
                        <img alt="" src="/back-long.png"/>
                    </a>
                    <a href="/" className="return-to-menu">
                        <div className="text-2xl font-semibold text-white" style={textStyle}>Return to menu</div>
                    </a>
                </div>
            </div>
            <div className="w-1/2 grid place-content-center md:flex-shrink ">
                {content[0].name && <Title text={content[0].name}  weight="500" color={color}></Title>}
            </div>
            <div className="w-1/4 "></div>
        </div>
    )
};

export default TitleBar;
