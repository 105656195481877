import React from 'react';


const Link = (props) => {
  const {text, url} = props;
    return (
        <a
        className="text-sm  mx-6 "
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        >
          {text}
      </a>
    )
};

export default Link;
