import React from 'react';


const Title = (props) => {
  const {text, size, weight, color} = props;
  const titleStyle = {
      fontWeight: weight || 'bold',
      color:color || 'black'
  }
    return (
        <p style={titleStyle} className=" md:text-xl  lg:text-2xl  xl:text-3xl  2xl:text-4xl">
          {text}
        </p>
    )
};

export default Title;
