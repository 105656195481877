import React, { Component } from 'react';
import { handleContentCategorySort } from '../../../utils/handleContentSort';
import Card from '../../molecules/card/card';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import './contentWithoutCategories.css'
import { handleContentState } from '../../../utils/handleContentState';

  const ContentWithoutCategories = (props) => {
    const {contentList, appName, appearance} = props;
    const appearanceStyle ={
      color: appearance.secondaryColor 
    }
    return (
      <div className=" flex justify-center mt-6">
        <div className="container flex justify-center">
          <div className="grid md:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3 2xl:grid-cols-5 gap-8 z-0">
              {contentList && contentList.map((content,index) => {
                const status = handleContentState(content);
                  return (
                    (status !== 'hidden') ? <Card key={index} content={content} appName={appName} appearance={appearance} status={status}></Card> : <div key={index} style={{display:"none"}}></div>
                  )
              })}
          </div>
        </div>
      </div>
      
    )
};

export default ContentWithoutCategories;