export const getHeaders = () => {
    const header = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'sec-fetch-site': 'cross-site',
      Accept: 'application/json',
    };
    
    return header;
  };


  const handleResponse = (response) => {
    if (!response.ok) {
      return Promise.reject(response);
    }
    return response.json();
  };
  
  const handleError = async (response) => {
    const error = await response.json().then((data) => data);
    return Promise.reject(error);
  };
  
  export const httpGet = (api, entryPoint = process.env.REACT_APP_API_ENDPOINT) => {
    const server = process.env.REACT_APP_BACKEND_URL + entryPoint;
    const headers = getHeaders();
    return fetch(server + api, {
      method: 'GET',
      headers,
    })
      .then(handleResponse)
      .catch(handleError);
  };
  
  export const httpPost = (api, data, entryPoint = process.env.REACT_APP_API_ENDPOINT) => {
    const server = process.env.REACT_APP_BACKEND_URL + entryPoint;
    const headers = getHeaders();
    return fetch(server + api, {
      method: 'POST',
      body: JSON.stringify(data),
      headers,
    })
      .then(handleResponse)
      .catch(handleError);
  };
  
  export const httpPut = (api, data, entryPoint = process.env.REACT_APP_API_ENDPOINT) => {
    const server = process.env.REACT_APP_BACKEND_URL + entryPoint;
    const headers = getHeaders();
    return fetch(server + api, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers,
    })
      .then(handleResponse)
      .catch(handleError);
  };
  
  export const httpDelete = (api, entryPoint = process.env.REACT_APP_API_ENDPOINT) => {
    const server = process.env.REACT_APP_BACKEND_URL + entryPoint;
    const headers = getHeaders();
    return fetch(server + api, {
      method: 'DELETE',
      headers,
    })
      .then(handleResponse)
      .catch(handleError);
  };
  