import React, { useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import RedeemCode from '../../molecules/redeemCode/redeemCode';
import './navbarTicket.css'

const NavbarTicket = (props) => {
  const {icon, appName, appearance} = props;
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const ref = useRef();

    return (
      <li className="nav-item">
        <div className="px-3 py-3 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75 cursor-pointer" onClick={()=>{setOpen(true)}}>
          <img alt="ticket icon" src={icon}></img>
        </div>
        <Popup ref={ref}  open={open} closeOnDocumentClick onClose={closeModal}>
          <div className=" popup-modal">
            <div className="flex justify-end">
                <a className="close cursor-pointer" onClick={closeModal}>
                  <img src="/Icons/exit.png" alt=""/>
                </a>
            </div>
            
            <RedeemCode appearance={appearance} appName={appName} reference={ref}></RedeemCode>
          </div>
        </Popup>
    </li>
    )
};

export default NavbarTicket;
