import React, { useEffect, useState } from 'react';
import Link from '../../atoms/link/link'
const List = (props) => {
    const {listContent, color} = props;

    return (
        < >
                <ul className="" style={{color:color || 'white'}}>
                {listContent && listContent.map((content,index) => {
                        return <Link key={index} text={content.name} url={content.url}></Link>
                    })}
                </ul>
            
        </>
    )
};

export default List;