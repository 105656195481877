import React from 'react';
import Button from '../../atoms/button/button';
import PendingActivation from '../pendingActivation/pendingActivation';
import RedeemCode from '../redeemCode/redeemCode';


const ModalContentStatus = (props) => {
    const {status, appName, content, reference, appearance} = props;
    let component;
    switch(status){
        case 'enabled':
            component = <Button appearance={appearance} active={true} content={content}></Button>
            break;
        case 'blocked':
            component = <RedeemCode reference={reference} appName={appName} appearance={appearance}></RedeemCode>
            break;
        case 'disabled':
            component = <div>
                            <Button active={false} appearance={appearance}></Button>
                            <p className=" text-center text-gray-500 text-xs mb-2">This content is blocked due to propery rights</p>
                        </div>
            break;
        case 'pending':
            component = <PendingActivation content={content}></PendingActivation>
            break;
        default:
            break;
    }
    return component;
};

export default ModalContentStatus;
