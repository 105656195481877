
import axios from 'axios';
import {
  httpGet, httpPost, httpPut, httpDelete,
} from '../api/httpCalls.js';
import YAML from 'yaml'
import { handleCreateAppNameCookie } from './handleCookies.js';

export const getContentList = (deviceId) => {
    const url= window.location.host;
    return handleConfigApi(url, deviceId)
  };

export const handleConfigApi = (url, deviceId) => {
  return httpGet(`configApi/`+url).then(app => {
    const contentList = axios.get(app.configApiUrl)
      .then(res =>{
        const parsedData = YAML.parse(res.data)
        handleCreateAppNameCookie(app.app.name)
        return initContentList(parsedData.contentList, deviceId, app.app.name, app.app.appName)
      })
      return contentList;
  })
}

export const initContentList = (contentListUrl, deviceId, appName, internalAppName) => {
  return axios.get(contentListUrl.split('[')[0] + deviceId)
        .then(res=> {
            return {...res.data, appName, internalAppName}
        })
}
