import React, { useEffect, useRef, useState } from 'react';
import { handleCreateCookie, handleGetCookies } from '../../../utils/handleCookies';
import { handleValidateToken } from '../../../utils/handleVirtualTicket';
import { useAlert, positions } from 'react-alert'

// import LoadingSpinnerCircle from '../../atoms/loadingSpinnerCircle/loadingSpinnerCircle'
import './redeemCode.css'
const RedeemCode = (props) => {
    const [inputToken, setInputToken] = useState();
    const alert = useAlert()
    // const [startLoader, setStartLoader] = useState(false);
    const {appName, reference, appearance} = props;
    const setTokenValue = (event) => {
        setInputToken(event.target.value)
    }
    const handleCookies = () => {
        return handleGetCookies('deviceId') ? handleGetCookies('deviceId') : handleCreateCookie();
    }
    const sendData = (event) => {
        event.preventDefault()
        handleValidateToken(inputToken, handleCookies(), appName).then(result=>{
            reference.current.close()
            if(result.type ==='error'){
                const error = Object.values(result.errors)[0];
                alert.show(error[0], {
                    timeout:3000,
                    type:result.type,
                    position: positions.MIDDLE,
                })
            } else {
                alert.show(result.message, {
                    timeout:3000,
                    type:result.type,
                    position: positions.MIDDLE,
                })
                setTimeout(function(){ window.location.reload(false)}, 1500)
            }
            })
    }
    const AppearanceStyle ={
        backgroundColor: appearance.primaryColor || '#6CC24A'
    }
    return(
        <div className="w-full h-52 redeem-code-container rounded-lg mb-4 mt-4">
            <div className="w-full h-14 header-redeem rounded-lg flex justify-center items-center space-x-2 " style={AppearanceStyle}>
                <div className="text-3xl ">
                    Redeem Code 
                </div>
                <img alt="watch icon" src="/Icons/ticket.png"></img>
            </div>
            <div className="w-full pt-6 pl-20 pr-20 relative">
                <div>
                    <form onSubmit={sendData}>
                        <div className="w-full">
                            <input className="w-full border-2 input-text rounded h-11 flex justify-center" placeholder="Enter code..." type="text" onChange={setTokenValue}/>
                        </div>
                        <div className="flex justify-center space-x-7 w-full mt-5 h-10 pb-4">
                            <button className="border-2 w-2/4 rounded cancel-button">Cancel</button>
                            <button className=" border-2 w-2/4 rounded send-button" style={AppearanceStyle}>Send</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
        )
    
};

export default RedeemCode;
