import { httpPost } from "../api/httpCalls"

export const handleValidateToken = (token, deviceId, appName) => {
    return httpPost(`virtualticket/validatetoken`, {
        'userValidationCode' : token,
        'deviceId':deviceId,
        'appName': appName,
        'platform':'app-web'
    }).then(res => {
        return {message:res.result[res.result.length - 1].message, type:'success'};
      }).catch(err=>{
          return {...err, type:'error'};
      })
}
