export const handleGetCookies = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;

}
export const handleCreateCookie = () => {
    const deviceId =  Math.floor(Math.random() * 99999999);
    document.cookie = `deviceId=${deviceId}; expires=Thu, 18 Dec 2030 12:00:00 UTC`;
    return deviceId;

}
export const handleCreateAppNameCookie = (appName) => {
  document.cookie = `appName=${appName}`;
  return appName;

}