import React from 'react';
import ContentList from '../../templates/contentList/contentList';
import Footer from '../../organisms/footer/footer';


const HomePage = (props) => {
    const {appearance, panelsOptions, sharedExperience, externalLinks, appName} = props;
    return (
    <div className="overflow-x-hidden">
        <ContentList appearance={appearance} panelsOptions={panelsOptions} appName={appName}></ContentList>
        <Footer appearance={appearance} externalLinks={externalLinks}></Footer>
    </div>
    )
};

export default HomePage;
