import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import LoadingIndicator from "./atoms/loadingSpinner/loadingSpinner";
import NavBar from "./organisms/navBar/navBar";
import HomePage from "./pages/homePage/homePage";
import VideoPlayer from "./pages/videoPlayer/videoPlayer";
import DeviceDetector from "device-detector-js";
import InformationMessage from "./molecules/informationMessage/informationMessage";
import {handleChangePlayerColor} from '../utils/handleCssImport'
function App(props) {
  const {appearance, purchaseUrl, panelsOptions, sharedExperience, externalLinks, appName, internalAppName} = props.contentList;
  const [device, setDevice] = useState();
  const [isIos, setIos] = useState();

function getFaviconEl() {
  return document.getElementById("favicon");
}
function createMetaTag(property, content){
  /*var link = document.createElement('meta');
  link.setAttribute('property', property);
  link.content = content;
  document.getElementsByTagName('head')[0].appendChild(link);*/
}
// document.querySelector('meta[name="description"]').setAttribute("content", 'wtf');

  useEffect(()=>{
    if(appearance !== undefined){
      const deviceDetector = new DeviceDetector();
      const userAgent = deviceDetector.parse(navigator.userAgent);
      const device = userAgent.device.type;
      setIos((/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
      !window.MSStream)
      setDevice(device)
      const favicon = getFaviconEl(); // Accessing favicon element
      favicon.href = appearance.favicon.enabled ? appearance.favicon.url : '';
      document.title = appName
      createMetaTag('og:image', appearance.favicon.enabled ? appearance.favicon.url : appearance.logo.url)
      appearance.description ? createMetaTag('og:description', appearance.description) : createMetaTag('og:description', '')
      handleChangePlayerColor(appearance.primaryColor);
    }
  })
  if( device && device!== "desktop" || isIos){
    return <div style={{backgroundColor:'#040C12', height:'100vh', maxHeight:'100vh', overflowY:'auto'}}>
    <InformationMessage header="Site Blocked" body="This site is unavailable on your device, please use it on a computer or download the mobile app"></InformationMessage>
  </div>
    
  } else {
    return (
      <Router>
        <NavBar appearance={appearance} purchaseUrl={purchaseUrl} appName={internalAppName} panelsOptions={panelsOptions}></NavBar>
        <LoadingIndicator/>
        <Switch>
          <Route path="/player">
              <VideoPlayer appearance={appearance}  panelsOptions={panelsOptions}/>
            </Route>
            <Route path="/">
              <HomePage  appearance={appearance} appName={internalAppName}   panelsOptions={panelsOptions}   sharedExperience={sharedExperience}   externalLinks={externalLinks} />
            </Route>
        </Switch>
      </Router>
    );
  }
  
}

export default App;
