import React, { useEffect, useState } from 'react';
import Logo from '../../atoms/logo/logo';
import NavbarItem from '../../atoms/navbarItem/navbarItem';
import NavbarTicket from '../../atoms/navbarTicket/navbarTicket';
import './navBar.css';
import Button from '../../atoms/button/button'
import { handleContentState } from '../../../utils/handleContentState';
const NavBar = (props) => {
  const [logoSrc, setLogoSrc] = useState();
  const [background, setBackground] = useState();
  const [purchaseUrlLink, setPurchaseUrl] = useState();
  const [redeemTicket, setRedeemTicket] = useState();

  const {appearance, appName, purchaseUrl, panelsOptions} = props;
 
  useEffect(() => {
    if (appearance !== undefined) {
      const src = appearance.logoWeb.enabled ? appearance.logoWeb.url: '/ybvr_dark_background.png';
      setLogoSrc(src)
      setBackground(appearance.backgroundColor)
      if(purchaseUrl.enabled){
        setPurchaseUrl(purchaseUrl.url)
      }
      setRedeemTicket(panelsOptions.mainPanel.videos.filter(video =>handleContentState(video) =='blocked').length > 0 ? true : false);
    }
  });
  const navbarStyle = {
    backgroundColor: background || '#040C12'
  }
    return (
        <nav style={navbarStyle} className="navbar flex flex-wrap items-center justify-between px-2 z-40">
        <div className="w-screen mx-auto px-8 flex flex-wrap items-center justify-between">
          <div className=" relative flex justify-between">
            <Logo href="/" imgSrc={logoSrc}></Logo>
          </div>
          <div>
            <ul className="flex flex-row">
                {redeemTicket && <NavbarTicket appearance={appearance} icon="/ticket.png" appName={appName}></NavbarTicket>}
                {purchaseUrlLink && <NavbarItem appearance={appearance} url={purchaseUrlLink}></NavbarItem>}
                
            </ul>
          </div>
        </div>
      </nav>
    )
};

export default NavBar;