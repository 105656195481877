import React, { useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Popup from 'reactjs-popup';
import RedeemCode from '../../molecules/redeemCode/redeemCode';
import './navbarItem.css'
import { withRouter } from 'react-router-dom'; 
const NavbarItem = (props) => {
    const {url} = props;
    let history = useHistory();

    const buttonStyle={
      border:'3px white solid',
      color: 'white'
    }
    const redirect = () => {
      window.open(url); 
      return null;
    }
    return (
      <li className="nav-item">
          <div style={buttonStyle} onClick={redirect} className="px-3 rounded pt-2 pb-2 mt-4 ml-4 mr-4  py-2 flex items-center text-md uppercase leading-snug text-white hover:opacity-75 cursor-pointer">
            <button className="navbarItemButton font-extrabold">Buy Now!</button>
          </div>`
    </li>
    )
};

export default withRouter(NavbarItem);