import React, { useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { handleSidePanelState } from '../../../utils/handleContentState';
import { handleHideDotsCss } from '../../../utils/handleCssImport';
import CarouselItem from '../../atoms/carouselItem/carouselItem';
import './carousel.css'
var CarouselComponent = require('react-responsive-carousel').Carousel; // requires a loader

const Carousel = (props) => {
    const {sidePanels} = props;
    const filteredPanels = sidePanels.filter(panel => handleSidePanelState(panel)==='visible');
    useEffect(()=>{
        if(filteredPanels.length === 1){
            handleHideDotsCss()
        }
    })
    return(
        <CarouselComponent className="carousel" dynamicHeight={true} showArrows={true} useKeyboardArrows={true} autoPlay={true} infiniteLoop={true} showThumbs={false}>
            {filteredPanels && filteredPanels.map((panel,index) => {
                return (
                    <CarouselItem key={index} panel={panel}></CarouselItem>
                );
            })}
        </CarouselComponent>
        )
    
};

export default Carousel;
