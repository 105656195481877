import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { handleContentStateIcon } from '../../../utils/handleContentState';
import Modal from '../../organisms/modal/modal';
import './card.css'

const StateIcon = (url, appearance) => {
    return url 
        ? 
            <div style={{backgroundColor: appearance.primaryColor || '#6CC24A'}} className="status-icon pt-2 pl-2 pb-2 pr-3 rounded-tr-2xl">
                <img src={url} alt="statusIcon" /> 
            </div>
        : 
        <div className="status-icon pt-2 pl-2 pb-2 pr-3 rounded-tr-2xl">
        <img src="/Icons/disabled.png" alt="/" style={{visibility:'hidden'}}/> 
            
        </div>
}

const Card = (props) => {
    const {content, appName, appearance, status} = props;
    const iconUrl = handleContentStateIcon(status);
    const ref = useRef();
    const toggleTooltip = () => ref.current.toggle();

    const [image, setImage] = useState();
    useEffect(()=>{
        if(content.poster.length > 0){
            setImage(content.poster)
        } else {
            setImage('https://via.placeholder.com/300x160')
        }
    },[])
        return (
            <div className="w-72 mr-4 ml-4" >
                <div className="z-0 w-full card-body">
                    <div className="card-icon" onClick={toggleTooltip}>
                        <div className="justify-end w-full flex">
                            {StateIcon(iconUrl, appearance)}
                        </div>
                    </div>
                    {status==="enabled" && <div className="rounded-2xl w-full shadow-lg" >
                        <Link to={`/player/${content.id}`} className="btn btn-primary">
                            {/* <img src="https://images.unsplash.com/photo-1600054800747-be294a6a0d26?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1053&q=80" alt="" className="rounded-2xl h-40 w-full"/> */}
                            <img src={image} alt="" className="rounded-2xl min-h-40 max-h-40 h-40 w-full"/>
                        </Link>
                    </div>}
                    {status!=="enabled" && <div className="rounded-2xl w-full shadow-lg" >
                            {/* <img src="https://images.unsplash.com/photo-1600054800747-be294a6a0d26?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1053&q=80" alt="" className="rounded-2xl h-40 w-full"/> */}
                            <img src={image} alt="" onClick={toggleTooltip} className="rounded-2xl min-h-40 max-h-40 h-40 w-full"/>
                    </div>}
                    
                    <div className="flex  text-lg text-white card-text h-5">
                        <div className="card-title w-full rounded-2xl flex ">
                            <div className=" card-name w-4/5 min-w-4/5 max-w-4/5 m-2"><p className="truncate">{content.name}</p></div>
                            <div className="justify-end w-1/5 m-2 flex justify-center">
                                <Modal reference={ref} icon="/Icons/information.png" type="contentInfo" appearance={appearance} content={content} state={status} appName={appName}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    
};



export default Card;