import React from 'react';
import { Link } from 'react-router-dom';
import './button.css'

const Button = (props) => {
    const {active, content, appearance} = props;
    const appearanceStyle = {
        backgroundColor: appearance.primaryColor || '#6CC24A'
    }
    if(active){
        return (
            <Link to={`/player/${content.id}`} className="button-component w-full flex justify-center items-center space-x-1 rounded-lg h-14 mb-3 mt-3" style={appearanceStyle}>
                <div className="text-2xl font-semibold">
                    Watch 
                </div>
                <img alt="watch icon" src="/Icons/watch.png"></img>
            </Link>
        )
    } else {
        return (
            <button disabled className="button-disabled w-full flex justify-center items-center space-x-1 rounded-lg h-14 mb-1 mt-3">
                <div className="text-2xl font-semibold">
                Disabled 
                </div>
                <img src="/Icons/disabledwhite.png" alt="disabled content" className="disabled-icon"></img>           
            </button>
        )
    }
    
};

export default Button;
