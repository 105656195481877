import React from 'react';
import { handleDateFormat } from '../../../utils/handleDateFormat';
import './pendingActivation.css'
const PendingActivation = (props) => {
    const {content} = props;
    const formattedDate = handleDateFormat(content.activationEpoch, 'ff')
    return(
        <div className="w-full h-40 pending-activation-container rounded-lg mb-4 mt-4">
            <div className="w-full h-14 header-pending rounded-t-lg flex justify-center items-center space-x-2 ">
                <div className="text-3xl ">
                    Available at
                </div>
                <img alt="watch icon" src="/Icons/calendarwhite.png" className="calendar-icon"></img>
            </div>
            <div className="w-full pt-4 pl-10 pr-10 text-center divide-y ">
                <div className="font-bold text-xl pb-3 ">
                    {formattedDate}
                </div>
                <div className="text-sm pt-2 small-text-date">
                    This video will be available on the date above
                </div>
            </div>
        </div>
        )
    
};

export default PendingActivation;
