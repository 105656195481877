import React from 'react';


const Logo = (props) => {
  const {href, imgSrc} = props;
    return (
        <a
        className="text-sm font-bold leading-relaxed inline-block  whitespace-nowrap uppercase text-white"
        href={href}
      >
        <img className=" object-contain" style={{height:'4rem'}}  src={imgSrc}></img>
      </a>
    )
};

export default Logo;
