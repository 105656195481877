export const handleChangePlayerColor = (primaryColor) => {
    var css = `
    .ybvr-progress-bar > div:first-child {
        background-color: ${primaryColor};
    }
    .ybvr-viewpoint--default--active:before,
    .ybvr-viewpoint--default:hover:before {
        background-color: ${primaryColor} !important;
       }
       .ybvr-viewpoint--default-active:before, .ybvr-viewpoint--default:hover:before {
        background-color: ${primaryColor} !important;

       }   
       
       `,
    head = document.head || document.getElementsByTagName('head')[0],
    style = document.createElement('style');

    head.appendChild(style);

    style.type = 'text/css';
    if (style.styleSheet){
    // This is required for IE8 and below.
    style.styleSheet.cssText = css;
    } else {
    style.appendChild(document.createTextNode(css));
    }
}
export const handleHideDotsCss = () => {
    var css = `
        .control-dots {
            display:none;
        }
       
       `,
    head = document.head || document.getElementsByTagName('head')[0],
    style = document.createElement('style');

    head.appendChild(style);

    style.type = 'text/css';
    if (style.styleSheet){
    // This is required for IE8 and below.
    style.styleSheet.cssText = css;
    } else {
    style.appendChild(document.createTextNode(css));
    }
}