import React from 'react';


const Link = (props) => {
  const {text} = props;
    return (
        <p
        className="text-sm text-left w-full break-words leading-relaxed "
      >
          {text}
      </p>
    )
};

export default Link;
