import React, { useEffect, useState } from 'react';
import { handleContentCategorySort } from '../../../utils/handleContentSort';
import Carousel from '../../molecules/carousel/carousel';
import ContentCategories from '../../organisms/contentCategories/contentCategories';
import ContentWithoutCategories from '../../organisms/contentWithoutCategories/contentWithoutCategories';
import './contentList.css'

const ContentList = (props) => {
    const {panelsOptions, appName, appearance} = props;

    const [contentList, setContentList] = useState();
    const [categoriesList, setCategoriesList] = useState([]);
    const [sidePanelsList, setSidePanelsList] = useState();
    const [background, setBackground] = useState();

    useEffect(() => {
        if (panelsOptions !== undefined) {
            setContentList(panelsOptions.mainPanel.videos);
            if(!panelsOptions.categoriesPanel.isDisabled){
                setCategoriesList(panelsOptions.categoriesPanel.categories);
            }
            setSidePanelsList(panelsOptions.sidePanels);
            setBackground(appearance.backgroundColor)
        }
      });
    const bodyStyle={
        backgroundColor: background || '#040C12'
    } 
    const hasCategories =  (categoriesList.length > 0 && handleContentCategorySort(contentList, categoriesList).filter((category)=>category.content.length > 0).length > 0 ? true : false);
    const content = hasCategories ? ContentListWithCategories(contentList, categoriesList, sidePanelsList, appName, bodyStyle, appearance) : ContentListWithoutCategories(contentList, categoriesList, sidePanelsList, appName, bodyStyle, appearance)
    return content
};

const ContentListWithCategories = (contentList, categoriesList, sidePanelsList, appName, bodyStyle, appearance) =>{
    return (
    <div style={bodyStyle} className="contentlist pt-20 pb-16">
        <Carousel sidePanels={sidePanelsList}></Carousel>
        <ContentCategories appearance={appearance} contentList={contentList} categoriesList={categoriesList} appName={appName}></ContentCategories>
    </div>
    )
}
const ContentListWithoutCategories = (contentList, categoriesList, sidePanelsList, appName, bodyStyle, appearance) =>{
    if(contentList !== undefined){
        return (
            <div style={bodyStyle} className="contentlist pt-20 pb-16">
                <Carousel sidePanels={sidePanelsList}></Carousel>
                <ContentWithoutCategories appearance={appearance} contentList={contentList} categoriesList={categoriesList} appName={appName}></ContentWithoutCategories>
            </div>
        )
    } else {
        return <div style={bodyStyle}></div>
    }

}
export default ContentList;
