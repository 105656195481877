import React, { useEffect, useState } from 'react';
import List from '../../molecules/list/list';
import './footer.css'
const Footer = (props) => {
    const [footerContent, setFooterContent] = useState([]);
    const [background, setBackground] = useState();
    const [textColor, setTextColor] = useState();
    const {externalLinks, appearance} = props;
    const currentDate = new Date();
    const year = currentDate.getFullYear();

    const footerStyle = {
        backgroundColor:background || '#040C12',
        color: textColor || 'white'
      }
      useEffect(() => {
        if (appearance !== undefined) {
            setBackground(appearance.backgroundColor)
            setTextColor(appearance.secondaryColor)
        }
      });
      useEffect(() => {
        if ((externalLinks !== undefined ) && (externalLinks.length > 0)) {
          setFooterContent(externalLinks)
        }
      });
    return (
        <div style={footerStyle} className="footer flex items-center  px-2">
            <div className="w-screen mx-auto px-8 flex flex-wrap items-center justify-between">
                <div className=" relative pl-5 flex justify-between">
                    ©YerbabuenaVR {year}
                </div>
                <div className="">
                    <List color={textColor} listContent={footerContent}></List>
                </div>
            </div>
        </div>
    )
};

export default Footer;