import React, { useEffect, useRef, useState } from 'react';
import './modal.css'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Text from '../../atoms/text/text';
import ModalContentStatus from '../../molecules/modalContentStatus/modalContentStatus';

const Modal = (props) => {
    const {icon, type, content, state, appName, appearance, reference} = props;
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    if(type === 'contentInfo'){
        const style = {
            content: `url("${content.poster}")`
            // content: `url("https://testimages.juliaimages.org/images/coffee.png")`
        }
    return (
        <div>
            <div>
                <img src={icon} alt="infoicon" className="more-info-icon" onClick={() => setOpen(o => !o)}/> 
            </div>
            <Popup open={open} lockScroll={true} overlayStyle={{overflowY:'auto'}} repositionOnResize={true} ref={reference} closeOnDocumentClick onClose={closeModal}>
                <div>
                    <div >
                        <div className="image" style={style}></div>
                    </div>
                </div>
                <div className="modal-container">
                    <div className="modal-title">
                        {content.name}
                    </div>
                    <div className="modal-time"></div>
                    <div>
                        <ModalContentStatus appearance={appearance} status={state} content={content} reference={reference} appName={appName}></ModalContentStatus>
                    </div>
                    <div className="modal-details">
                        <div className="modal-details-title">Details</div>
                        <Text text={content.description}></Text>
                    </div>
                </div>
                <div></div>
            </Popup>
        </div>
        )
    }
};



export default Modal;