export const handleContentCategorySort = (contentList, categoriesList) => {
    return categoriesList.filter(category => !category.hidden).map(category =>{
        const categoryName = category.name;
        const content = contentList.filter(content =>{
            if(content.visibility !== "hidden" && !!content.categories.find(i => i.name === categoryName)){
                return content;
            }
        })
        return {category: categoryName, content}
    })
}