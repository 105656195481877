import React from 'react';
import './informationMessage.css'

const InformationMessage = (props) => {
    const {header, body, date} = props;
    return (
        <div className=" grid place-items-center information-container">
            <div className="container lg:w-2/5 sm:w-screen md:m-10 md:px-20  rounded-lg information-body">
                <div className=" mt-28  flex justify-center m-8">
                    <img src="/LargeIcons/information.png" className="w-36" alt=""/>
                </div>
                <div className="flex flex-col justify-center m-8 mb-28 text-white">
                    <div className=" text-4xl font-bold text-center">
                        {header}
                    </div>
                    {date && 
                        <div className="text-center text-2xl mt-4 font-semibold">
                            {date}
                        </div>
                    }
                    {body && 
                        <div className="text-center text-lg mt-4">
                            {body}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
};

export default InformationMessage;
