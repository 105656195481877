var { DateTime } = require('luxon');

export const handleContentStateIcon = (state) => {
    let status;
    switch(state)
    {
        case 'enabled':
            status =  null;
            return status;
            break;
        case 'blocked' : 
            status =  "/Icons/ticket.png";
            return status;
            break;
        case 'disabled' : 
            status =  "/Icons/disabled.png";
            return status;
            break;
        case 'pending' : 
            status =  "/Icons/calendar.png";
            return status;
            break;
        default: 
            return null;
            break;       
    }
    return status
}

export const handleContentState = (content) => {
    const {visibility, activationEpoch, expirationEpoch} = content;
    const isActive = DateTime.fromSeconds(activationEpoch).toISO() <= DateTime.now().toISO();
    const isExpired = expirationEpoch !== 0 ? DateTime.now().toISO() >= DateTime.fromSeconds(expirationEpoch).toISO() : false; 
    if(visibility === 'enabled' && isActive && !isExpired){
        return 'enabled';
    }
    if(visibility === 'enabled' && isActive && isExpired){
        return 'hidden';
    }
    if(visibility === 'enabled' && !isActive){
        return 'pending';
    }
    if(visibility === 'disabled'  && !isActive){
        return 'disabled';
    }
    if(visibility === 'disabled'  && isActive && !isExpired){
        return 'disabled';
    }
    if(visibility === 'blocked'  && isActive && !isExpired){
        return 'blocked';
    }
    if(visibility === 'disabled'  && isActive && isExpired){
        return 'hidden';
    }
    if(visibility === 'blocked'  && !isActive){
        return 'blocked';
    }
    if(visibility === 'blocked'  && isActive && isExpired){
        return 'hidden';
    }
    if(visibility === 'hidden'){
        return 'hidden';
    }
    return "";
}
export const handleSidePanelState = (panel) => {
    const video = panel.videos;
    if(panel.isDisabled || video.length === 0 || video[0].visibility === 'hidden'){
        return 'hidden';
    }
    return 'visible'
}
