import React, { useEffect, useState } from 'react';
import { handleContentState } from '../../../utils/handleContentState';
import { handleDateFormat } from '../../../utils/handleDateFormat';
import { handleGetVideo } from '../../../utils/handleVideo';
import InformationMessage from '../../molecules/informationMessage/informationMessage';
import TitleBar from '../../molecules/titleBar/titleBar';
import Player from '../../organisms/player/player';


const VideoPlayer = (props) => {
    const { panelsOptions, appearance} = props;
    const [video, setVideo] = useState();
    const [status, setStatus] = useState();
    const [color, setColor] = useState();

    useEffect(()=>{
        if(appearance){
            setColor(appearance.backgroundColor)
        }
    })

    const bodyStyle={
        backgroundColor: color || '#040C12',
    } 
    useEffect(() => {
        if(panelsOptions){
            setVideo(handleGetVideo(window.location.pathname, panelsOptions.mainPanel.videos));
        }
      },[panelsOptions]);
    useEffect(() => {
        if(video){
            setStatus(handleContentState(video[0]))
        }
      },[video]);
    return (
        <div className="pt-20 h-screen min-h-full w-screen" style={bodyStyle}>
            {video && <TitleBar color={appearance.secondaryColor} content={video}></TitleBar>}
            {(video && status === "enabled") && <Player content={video} appearance={appearance}></Player>}
            {(video && status === "disabled" || status === "hidden") && <InformationMessage header="Content Unavailable"></InformationMessage>}
            {(video && status === "blocked") && <InformationMessage header="Content Blocked" body="You must activate a token to view this content"></InformationMessage>}
            {(video && status === "pending") && <InformationMessage header="Pending Activation" date={handleDateFormat(video[0].activationEpoch, 'ff')} body=" This video will be available on the date above"></InformationMessage>}
        </div>
    )
};

export default VideoPlayer;
