import React, { Component, useState } from 'react';
import { handleContentCategorySort } from '../../../utils/handleContentSort';
import Card from '../../molecules/card/card';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import './contentCategories.css'
import { handleContentState } from '../../../utils/handleContentState';
var { DateTime } = require('luxon');

  const MenuItem = ({content, appName, appearance, status}) => {
    const [state, setState] = useState(status);
    if(((content.activationEpoch - DateTime.now().toSeconds()) > 0) && (content.activationEpoch - DateTime.now().toSeconds()) < 86400){
      setInterval(()=>{
        setState(handleContentState(content))
      }, 5000);
    }
    return <Card content={content} appName={appName} appearance={appearance} status={state}></Card>;
  };

  export const Menu = (list, appName, appearance) =>
    list.map((el, index) => {
      const {name} = el;
      const status = handleContentState(el);
      return  (status !== 'hidden') ? <MenuItem content={el} key={name + index} appName={appName} appearance={appearance} status={status}/> : <></>;
    });
  
  
  const Arrow = ({ url, className }) => {
    return (
      <div
        className={className}
      ><img alt="arrow" src={url}></img></div>
    );
  };
  
  
  const ArrowLeft = Arrow({ url: '/Icons/back.png', className: 'arrow-prev ' });
  const ArrowRight = Arrow({ url: '/Icons/next.png', className: 'arrow-next ' });
  
  const ContentCategories = (props) => {
    const {contentList, categoriesList, appName, appearance} = props;
    const categoryContent = handleContentCategorySort(contentList, categoriesList).filter(category => category.content.length > 0  && category.content.filter(content=>handleContentState(content) !== "hidden").length > 0);
    const appearanceStyle ={
      color: appearance.secondaryColor 
    }
    return (
        <div>
            {categoryContent && categoryContent.map((category,index) => {
                return (
                <div key={index} className=" flex items-center categories">
                    <div className="w-screen mt-8   ">
                        <div className="text-2xl mb-2 pl-16" style={appearanceStyle}>
                            {category.category}
                        </div>
                        <div className="flex w-full">
                                <div className="App">
                                        <ScrollMenu
                                                data={Menu(category.content, appName, appearance)}
                                                arrowLeft={ArrowLeft}
                                                arrowRight={ArrowRight}
                                                wrapperStyle={{width:'94vw', paddingRight:'6rem'}}
                                                itemStyle={{padding:'1rem 0rem 1rem 0rem'}}
                                                // innerWrapperClass={"mt-6"}
                                                alignCenter={false}
                                                hideArrows={true}
                                                hideSingleArrow={true}
                                                wheel={false}
                                            />
                                    </div>
                        </div>
                    </div>
                </div>
                )
            })}
        </div>
    )
};

export default ContentCategories;