import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import './loadingSpinner.css'
const LoadingIndicator = props => {
const { promiseInProgress } = usePromiseTracker();
const bodyStyle={
    backgroundColor:'#040C12',
    color:'white'
} 
       return (
        promiseInProgress && 
        <div style={bodyStyle} className="h-screen flex items-center justify-center spinner"> 
            <div className="loader">Loading...</div>
        </div>
      );  
}

export default LoadingIndicator;
