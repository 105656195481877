export const handleGetVideo = (pathname, videos) => {
    const id = handlePathName(pathname);
    const video = handleFilterVideos(id, videos);
    return video
}
export const handlePathName = (pathname) => {
    let splitPath = pathname.split('/');
    if(splitPath.length === 3){
        return splitPath[2]; 
    }
}
export const handleFilterVideos = (id, videos) => {
    return videos.filter(video => video.id == id);
}